import { API } from 'aws-amplify';
import { getSortedWeek } from 'layouts/applications/attendance/utils/attendanceUtils';
export const getWeeksData = async (currentSemesterId, fullSemester) => {
  const weeks = await API.get('delta-api', 'school-weeks', {
    headers: {},
    queryStringParameters: {
      semester: currentSemesterId, //get from semester select box
      fullSemester: fullSemester,
    },
  });
  getSortedWeek(weeks);
  return weeks;
};

export const getStudentAttendance = async (
  currentSemesterId,
  tabValue,
  fullSemester,
  watchedOnly,
  drop,
  includeCeStudents,
  instructor
) => {
  const attendance = await API.get(
    'delta-api',
    `online-attendance/semester/${currentSemesterId}/${tabValue}`,
    {
      headers: {},
      queryStringParameters: {
        fullSemester: fullSemester,
        watchedOnly: watchedOnly,
        dropped: drop,
        includeCeStudents: includeCeStudents,
        instructor: instructor,
      },
    }
  );
  return attendance['items'];
};

export const postStudentAttendance = async (postParams) => {
  const myInit = {
    queryStringParameters: postParams,
    headers: {},
  };
  await API.post('delta-api', 'online-attendance', myInit);
};
export const postStudentAttendanceNote = async (payload, studentId) => {
  const myInit = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `online-attendance/student/${studentId}/note`,
    myInit
  );
};
export const putStudentTag = async (postParams, queryStringParam) => {
  const myInit = {
    body: postParams,
    headers: {},
  };
  const { studentId } = queryStringParam;
  await API.put(
    'delta-api',
    `online-attendance/student/${studentId}/tags`,
    myInit
  );
};
export const putStudentAttendance = async (postParams) => {
  const myInit = {
    queryStringParameters: postParams,
    headers: {},
  };
  await API.put('delta-api', 'online-attendance', myInit);
};
export const deleteStudentAttendance = async (postParams) => {
  const myInit = {
    queryStringParameters: postParams,
    headers: {},
  };
  await API.del('delta-api', 'online-attendance', myInit);
};
export const getOnlineAttendanceSync = async () => {
  const syncData = await API.get('delta-api', `online-attendance/sync`);
  return syncData;
};
export const postOnlineAttendanceSync = async () => {
  await API.post('delta-api', `online-attendance/sync`);
};
export const getSemesterDetails = async () => {
  const attendance = await API.get('delta-api', `online-attendance/semesters`, {
    headers: {},
  });
  return attendance;
};

export const getAttendanceFilterOptions = async () => {
  const attendanceFilterOptions = await API.get(
    'delta-api',
    `online-attendance/filterOptions`
  );
  return attendanceFilterOptions;
};

export const _setSelectSemester = (state, action) => {
  state.selectedSemester = action.payload;
};

export const _setFullSemester = (state, action) => {
  state.fullSemester = action.payload;
};

export const _setWatchedOnly = (state, action) => {
  state.watchedOnly = action.payload;
};

export const _setDrop = (state, action) => {
  state.drop = action.payload;
};

export const _setIncludeCeStudents = (state, action) => {
  state.includeCeStudents = action.payload;
};

export const _setInstructor = (state, action) => {
  state.instructor = action.payload;
};

export const _setIsChanged = (state, action) => {
  state.isChanged = action.payload;
};

export const _setTabValue = (state, action) => {
  state.tabValue = action.payload;
};

export const _setIsAttendanceAudit = (state, action) => {
  state.isAttendanceAudit = action.payload;
};

import { createSlice } from '@reduxjs/toolkit';
import initialState from './semesterInitialState';
import {
  _setDrop,
  _setIncludeCeStudents,
  _setFullSemester,
  _setIsChanged,
  _setSelectSemester,
  _setWatchedOnly,
  _setInstructor,
  _setTabValue,
  _setIsAttendanceAudit,
} from './semesterAction';

export const semesterSlice = createSlice({
  name: 'delta',
  initialState,
  reducers: {
    setSelectSemester: _setSelectSemester,
    setFullSemester: _setFullSemester,
    setWatchedOnly: _setWatchedOnly,
    setDrop: _setDrop,
    setIncludeCeStudents: _setIncludeCeStudents,
    setInstructor: _setInstructor,
    setTabValue: _setTabValue,
    setIsChanged: _setIsChanged,
    setIsAttendanceAudit: _setIsAttendanceAudit,
    resetSelectSemester: () => initialState,
  },
});

export const {
  setSelectSemester,
  setFullSemester,
  setWatchedOnly,
  setDrop,
  setIncludeCeStudents,
  setInstructor,
  setTabValue,
  setIsChanged,
  setIsAttendanceAudit,
  resetSelectSemester,
} = semesterSlice.actions;

export default semesterSlice.reducer;
